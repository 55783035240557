<template>
  <div>
    <!-- clints section Start -->
    <section class="clints__section container py-5">
      <h2 class="text-uppercase text-center">SELECTED CUSTOMERS</h2>

      <div class="row container d-flex justify-content-center clint__row">
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/clints/Amberg-Loglay-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/clints/e1b9c69b-4ea2-4599-b6fe-c94b9122963c-1-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/clints/franke-logo-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/clints/Generali-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/clints/investiere-logo-large-proper-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/clints/Liberty-Global-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/clints/Lderach-logo-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/clints/migros-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/clints/odc_logo_new-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/clints/sunrise-logo-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/clints/SWICA-LOGO-Square-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/clints/SwissLife-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/clints/UPC-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/clints/Virgin-mobile-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/clints/Vorwerk-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
      </div>
    </section>
    <!-- clints section End -->
  </div>
</template>

<script>
export default {};
</script>