// HomeComponent.vue

<template>
  <div>
      <carousel-component/>
      <selies-component/>
      <how-component/>
      <client-component/>
      <partner-component/>
      <feedback-component/>
      <privacy-component/>
  </div>
</template>

<script>
    import CarouselComponent from './home/CarouselComponent.vue';
    import ClientComponent from './home/ClientComponent.vue';
    import FeedbackComponent from './home/FeedbackComponent.vue';
    import HowComponent from './home/HowComponent.vue';
    import PartnerComponent from './home/PartnerComponent.vue';
import PrivacyComponent from './home/PrivacyComponent.vue';
    import SeliesComponent from './home/SeliesComponent.vue';
    export default {
  components: { CarouselComponent, SeliesComponent, HowComponent, ClientComponent, PartnerComponent, FeedbackComponent, PrivacyComponent },
        mounted() {
            console.log('Component mounted.')
        }
        
    }
</script>
