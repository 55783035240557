<template>
  <div>
    <!-- Feedback section Start -->
    <section class="feedback__section container-fluid py-5">
      <div class="container">
        <h2 class="text-center">CUSTOMER OPINIONS</h2>
        <div class="container d-flex align-items-center justify-content-center">
          <div class="row mt-5 w-75">
            <div class="col-lg-12 text-center feedback__content">
              <q class="feedback__q">Vigilant Leadership Team</q>
              <p>
                ELISE has gone to great lengths to create a setup that fits our
                specific needs. Their leadership team is not afraid to face
                tough questions and will always give you a straight answer –
                priceless when building up a new team halfway round the globe.
              </p>
              <img
                src="/assets/Img/15-03-30_LukasWeber_jpeg-9184-1-150x150.jpg"
                alt=""
                class="mb-3"
              />
              <h5>Lukas Weber</h5>
              <p>Managing Partner | Investiere</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Feedback section End -->
  </div>
</template>

<script>
export default {};
</script>