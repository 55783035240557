<template>
  <div>
    <!--  partners section Start -->
    <section class="clints__section container py-5">
      <h2 class="text-uppercase text-center">partners</h2>

      <div class="row container d-flex justify-content-center clint__row">
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/partners/AWS-logo-new-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/partners/flury-new.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/partners/Microsoft-Logo-new-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/partners/Swisscom-logo-new-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="card clint__card" style="width: 10rem; border: none">
            <img
              src="/assets/Img/partners/WAgile-logo-new-150x150.png"
              class="card-img-top"
              alt="..."
            />
          </div>
        </div>
      </div>
    </section>
    <!-- partners section End -->
  </div>
</template>

<script>
export default {};
</script>