<template>
  <div>
    <!-- Data Privacy section Start -->
    <section class="data__privacy_section container-fluid py-5">
      <div class="container">
        <h2 class="text-center data__privacy_title">
          WE UNDERSTAND DATA PRIVACY
        </h2>
        <div class="container d-flex align-items-center justify-content-center">
          <div class="row mt-5 w-75">
            <div class="col-lg-12 text-center data__privacy__content">
              <p>
                EU’s General Data Protection Regulation (GDPR 2016/679)
                Compliant
              </p>
              <p>
                Swiss Federal Data Privacy Regulation on Cross Border Dataflow
                (Art. 6 DSG) Compliant
              </p>
              <p>Meet our Data Protection Officer Att. Yves Roger Gogniat</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Data Privacy section End -->
  </div>
</template>

<script>
export default {};
</script>