<template>
    <div>
        <!-- How its work section Start -->
        <section class="how__section container-fluid py-5">
            <div>
            <h2 class="text-center ht__title">How its work</h2>
            <div class="container">
                <div class="row mt-5">
                <div class="col-lg-4 col-sm-12 ht__div">
                    <h2>STRATEGY.</h2>
                    <p>Our genuine understanding of your business goals is a key building block of our services. We develop digital platform strategies, calculate business cases and come up with generic expansion plans for a number of customers every year.</p>
                </div>
                <div class="col-lg-4 col-sm-12 ht__div">
                    <h2>PROCESS.</h2>
                    <p>With a solid strategy carved out, we cross check the existing business processes against the ambitions. We enhance existing processes and form new ones using business process reengineering techniques taught at the University of St. Gallen.</p>
                </div>
                <div class="col-lg-4 col-sm-12 ht__div">
                    <h2>SYSTEM.</h2>
                    <p>Once strategy and processes are aligned, a digital platform, a piece of enterprise software or a mobile app can generate business success. We conduct research, build proof of concepts and prototypes to ensure what we intend to build fits this goal.</p>
                </div>
                </div>
            </div>
            </div>
        </section>
        <!-- How its work section End -->
    </div>
</template>

<script>
export default {
    
}
</script>