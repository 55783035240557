// App.vue

<template>
  <!-- Nav Section Start -->
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-white navbar_section px-3" id="navArea">
      <div class="container-fluid">
        <!-- Logo Start -->
        <a class="navbar-brand header__logo d-flex align-items-center h-100" href="#">
          <img src="/assets/Img/Softronixs Logo.png" alt="logo" class="" id="header__logo">
        </a>
      <!-- Logo End -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto mb-2 mb-lg-0 text-center pt-2 navbar__ul">
            <!-- Dropdown -->
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">Industries</a>

                    <ul class="dropdown-menu text-center" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" href="#">Telecom</a></li>
                        <li><a class="dropdown-item" href="#">Financial Services</a></li>
                        <li><hr class="dropdown-divider"></li>
                        <li><a class="dropdown-item" href="#">Smartcity</a></li>
                    </ul>
              </li>
          <li class="nav-item">
            <a class="nav-link" href="#">News</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Career</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Contact Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Suppport</a>
          </li>
        </ul>
        </div>
      </div>
    </nav>
    <!-- Nav Section End -->
    <router-view></router-view>

    <!-- Footer Start -->
  <footer class="container-fluid">
    <div class="row footer__menu_row pb-5">
      <div class="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center   pt-5 flex-column footer__col">
        <P>Our customer-first approach is geared to fuel fresh narratives about technology. We craft digital value to help customers worldwide rise above their competition.</P>
          <div class="social__handel">
            <i class="fab fa-facebook"></i>
            <i class="fab fa-instagram"></i>
            <i class="fab fa-twitter-square"></i>
            <i class="fab fa-youtube"></i>
            <i class="fab fa-linkedin-in"></i>
          </div>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 text-center pt-5">
        <div class="row d-flex  justify-content-between container">
          <div class="col-lg-2 col-md-3 col-sm-12 footer__col footer__col_2 ">
            <h2>COMPANY</h2>
            <ul class="w-100">
              <li>
                <a href="#">About Us</a>
              </li>
              <li>
                <a href="#">Contact Us</a>
              </li>
              <li>
                <a href="#">Culture</a>
              </li>
              <li>
                <a href="#">Terms Of Service</a>
              </li>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-12 footer__col footer__col_3">
            <h2>INDUSTRIES</h2>
            <ul>
              <li>
                <a href="#">Telecom</a>
              </li>
              <li>
                <a href="#">Financial Services</a>
              </li>
              <li>
                <a href="#">Logistics</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-12 footer__col footer__col_4">
            <h2>PRODUCTS</h2>
            <ul>
              <li>
                <a href="#">Business</a>
              </li>
              <li>
                <a href="#">Processes</a>
              </li>
              <li>
                <a href="#">Auditing</a>
              </li>
              <li>
                <a href="#">Community</a>
              </li>
              <li>
                <a href="#">Page Builder</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-12 footer__col footer__col_5">
            <h2>SERVICES</h2>
            <ul>
              <li>
                <a href="#">Technology</a>
              </li>
              <li>
                <a href="#">Web</a>
              </li>
              <li>
                <a href="#">Software Renovation</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row cr__row py-3">
      <div class="col-12 text-center d-flex justify-content-center align-items-center">
        <p class="d-flex justify-content-center align-items-center">©2020 <a href="https://softronixs.com" style="text-decoration: none; color: #fff;margin: 0 3px; color: #df5979;" target="_blank" > <strong>Softronixs Ltd.</strong></a> All Rights Reserved.</p>
      </div>
    </div>
  </footer>
  <!-- Footer End -->
  </div>
    
</template>

<style>
    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s
    }
    .fade-enter, .fade-leave-active {
      opacity: 0
    }
</style>

<script>

    export default{
    }
</script>
