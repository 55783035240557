<template>
  <div>
    <!-- Hero Carousel Start -->
    <section class="hero__carousel_section container-fluid p-0">
      <div
        id="carouselExampleDark"
        class="carousel carousel-dark slide"
        data-ride="carousel"
      >
        <div
          class="hero__c_item carousel-item active hero__cr"
          data-interval="10000"
        >
          <img src="/assets/Img/0.jpg" class="d-block w-100" alt="..." />
          <div class="overlay"></div>
          <div
            class="carousel-caption hero__txt_div text-center align-items-center justify-content-center d-flex flex-column"
          >
            <h2>
              Building telecom ecosystems: Globalinternet connects to Liberty
              Global using Softronixs technology
            </h2>
            <p>
              By integrating the Softronixs-customized API for generating
              quotes, Globalinternet has formed cross-corporate digital
              ecosystems with various vendors, including telecom giant...
            </p>
            <a href="#" class="btn btn-info hero__btn">Read More</a>
          </div>
        </div>
        <!-- <div class="hero__c_item hero__cr carousel-item" data-interval="2000">
          <img
            src="/assets/Img/4024909-scaled.jpg"
            class="d-block w-100"
            alt="..."
          />
          <div class="overlay"></div>
          <div
            class="carousel-caption hero__txt_div text-center align-items-center justify-content-center d-flex flex-column"
          >
            <h2>Introducing SAFIRE: The Jewel in Softronixs’s Design System</h2>
            <p>
              Softronixs introduces SAFIRE, a highly efficient,
              framework-agnostic design system that ensures consistency across
              multiple projects.
            </p>
            <a href="#" class="btn btn-info hero__btn">Read More</a>
          </div>
        </div> -->
        <div class="hero__c_item hero__cr carousel-item">
          <img
            src="/assets/Img/webbanner-wt-3.1-1.jpg"
            class="d-block w-100"
            alt="..."
          />
          <div class="overlay"></div>
          <div
            class="carousel-caption hero__txt_div text-center align-items-center justify-content-center d-flex flex-column"
          >
            <h2>
              Softronixs facilitates easy and cost-effective legacy code
              conversion with Phoenix
            </h2>
            <p>
              Combining the most advanced migration methodology with the
              experienced resources of Softronixs, Phoenix can help facilitate
              conversions at highly competitive...
            </p>
            <a href="#" class="btn btn-info hero__btn">Read More</a>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleDark"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleDark"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </a>
    </section>
    <!-- Hero Carousel End -->
  </div>
</template>

<script>
export default {};
</script>