import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import HomeComponent from './components/HomeComponent.vue';

export default new Router({
    mode: "history",
    routes: [
        {
            name: 'home',
            path: '/',
            component: HomeComponent
        }
    ]
  });