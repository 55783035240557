<template>
    <div>
        <!-- Selies info section Start -->
        <section class="selies__info_section">
            <div class="container-fluid selies__info_main_div">
            <div class="selies__info_title_div text-center  container">
                <h2 class="text-uppercase pb-5">Softronixs</h2>
                <p>Softronixs is a software firm engaged in the creation of digital platforms from strategy to execution. Our understanding of information technology and the internet helps us enhance business performance across industries.</p>
            </div>
            <!-- --Selies info tab Start -->
            <div class="Selies__info_tab w-100 d-flex flex-column align-items-center py-5">
                <div class="position-relative w-100 tab__ul_div container">
                <ul class="nav nav-pills mb-3 d-flex justify-content-evenly w-100" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                    <a class="nav-link active text-center" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">
                        <i class="fas fa-cogs  py-3"></i>
                        <p>Digital Platforms</p>
                    </a>
                    </li>
                    <li class="nav-item" role="presentation">
                    <a class="nav-link text-center" id="pills-ec-tab" data-toggle="pill" href="#pills-ec" role="tab" aria-controls="pills-ec" aria-selected="false">
                        <i class="fas fa-cloud-upload-alt py-3"></i>
                        <p>Enterprise Cloud</p>
                    </a>
                    </li>
                    <li class="nav-item" role="presentation">
                    <a class="nav-link text-center" id="pills-ca-tab" data-toggle="pill" href="#pills-ca" role="tab" aria-controls="pills-ca" aria-selected="false">
                        <i class="fas fa-mobile-alt py-3"></i>
                        <p>Custom Applications</p>
                    </a>
                    </li>
                    <li class="nav-item" role="presentation">
                    <a class="nav-link text-center" id="pills-em-tab" data-toggle="pill" href="#pills-em" role="tab" aria-controls="pills-em" aria-selected="false">
                        <i class="fas fa-dumpster py-3"></i>
                        <p>Ecommerce Websites</p>
                    </a>
                    </li>
                    <li class="nav-item" role="presentation">
                    <a class="nav-link text-center" id="pills-am-tab" data-toggle="pill" href="#pills-am" role="tab" aria-controls="pills-am" aria-selected="false">
                        <i class="fas fa-tasks py-3"></i>
                        <p>Application Management</p>
                    </a>
                    </li>
                </ul>
                </div>
                
                <div class="tab-content container tab__content_div " id="pills-tabContent">
                <!-- 1 -->
                <div class="tab-pane fade show active " id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div class="row" style="min-height: 300px;">
                    <div class="col-lg-6 col-md-12 d-flex position-relative my-3 justify-content-center align-items-center" style=" min-height: 400px;" >
                        <img src="/assets/Img/Capture.PNG" alt="" style="width: 100%; height: 100%; position: absolute; object-fit: contain;" class= "">
                    </div>
                    <div class="col-lg-6 col-md-12 d-flex  flex-column">
                        <h2 class="py-3">Digitizing business at scale</h2>
                        <p>
                        Consequat occaecat ullamco amet non eiusmod nostrud dolore irure incididunt est duis anim sunt officia. Fugiat velit proident aliquip nisi incididunt nostrud exercitation proident est nisi. Irure magna elit commodo anim ex veniam culpa eiusmod id nostrud sit cupidatat in veniam ad. Eiusmod consequat eu adipisicing minim anim aliquip cupidatat culpa excepteur quis. Occaecat sit eu exercitation irure Lorem incididunt nostrud. Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, aspernatur provident quae voluptatum, iste odio consequuntur nesciunt, itaque vel dolores quo eligendi quos eius deserunt voluptatibus id obcaecati ullam eos?
                        </p>
                    </div>
                    </div>
                </div>
                <!-- 2 -->
                <div class="tab-pane fade" id="pills-ec" role="tabpanel" aria-labelledby="pills-ec-tab">
                    <div class="row" style="min-height: 300px;">
                    <div class="col-lg-6 col-md-12 d-flex position-relative my-3 justify-content-center align-items-center" style=" min-height: 400px;" >
                        <img src="/assets/Img/Capture2.PNG" alt="" style="width: 100%; height: 100%; position: absolute; object-fit: contain;" class= "">
                    </div>
                    <div class="col-lg-6 col-md-12 d-flex  flex-column justify-content-center">
                        <h2 class="py-3">Microservices based architecture</h2>
                        <p>
                        We draw on a variety of technical components and business knowhow in order to create and maintain our digital platforms at scale. We take advantage of a micro services based architecture and frontend components that are upgraded to the latest technologies on a monthly basis.
                        </p>
                    </div>
                    </div>
                </div>
                <!-- 3 -->
                <div class="tab-pane fade" id="pills-ca" role="tabpanel" aria-labelledby="pills-ca-tab">
                    <div class="row" style="min-height: 300px;">
                    <div class="col-lg-6 col-md-12 d-flex position-relative my-3 justify-content-center align-items-center" style=" min-height: 400px;" >
                        <img src="/assets/Img/Capture3.PNG" alt="" style="width: 100%; height: 100%; position: absolute; object-fit: contain;" class= "">
                    </div>
                    <div class="col-lg-6 col-md-12 d-flex  flex-column justify-content-center">
                        <h2 class="py-3">Rapid application development</h2>
                        <p>
                        We tailor-make modern cloud apps on what we believe are the most future proof modern technologies for web and mobile. Our current stack is using .Net Core, MongoDB, Angular, Node.js, Ruby on Rails, PostgreSQL, Nativescript and runs on a Kubernetes orchestrator.
                        </p>
                    </div>
                    </div>
                </div>
                <!-- 4 -->
                <div class="tab-pane fade" id="pills-em" role="tabpanel" aria-labelledby="pills-em-tab">
                    <div class="row" style="min-height: 300px;">
                    <div class="col-lg-6 col-md-12 d-flex position-relative my-3 justify-content-center align-items-center" style=" min-height: 400px;" >
                        <img src="/assets/Img/Capture4.PNG" alt="" style="width: 100%; height: 100%; position: absolute; object-fit: contain;" class= "">
                    </div>
                    <div class="col-lg-6 col-md-12 d-flex  flex-column justify-content-center">
                        <h2 class="py-3">Scalable websites, shops and marketplaces</h2>
                        <p>
                        We build websites and online stores on enterprise-grade WordPress and AEM backbones. We are partners of Adobe and have substantial contributions to the WordPress community. We make sure that you have a striking presence online by providing creative designs and content from our partner agency Softronixs CREATIVE.
                        </p>
                    </div>
                    </div>
                </div>
                <!-- 5 -->
                <div class="tab-pane fade" id="pills-am" role="tabpanel" aria-labelledby="pills-am-tab">
                    <div class="row" style="min-height: 300px;">
                    <div class="col-lg-6 col-md-12 d-flex position-relative my-3 justify-content-center align-items-center" style=" min-height: 400px;" >
                        <img src="/assets/Img/Capture5.PNG" alt="" style="width: 100%; height: 100%; position: absolute; object-fit: contain;" class= "">
                    </div>
                    <div class="col-lg-6 col-md-12 d-flex  flex-column justify-content-center">
                        <h2 class="py-3">Running applications and infrastructure</h2>
                        <p>
                        The unified way in which we build applications ensures that coding and maintenance efforts are limited to specific business functions. Our tenant-aware architecture allows us to host enterprise applications at a fraction of their standalone cost. We service applications within our VPCs at AWS and Azure by offering holistic ITSM standards.
                        </p>
                    </div>
                    </div>
                </div>
                <!-- 5 end -->
                
                </div>
            </div>
            <!-- --Selies info tab End -->
            
            <!-- --Selies info tab End -->
            </div>
        </section>
        <!-- Selies info section End -->
    </div>
</template>

<script>
export default {
    
}
</script>